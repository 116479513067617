import React, { useRef, useEffect } from 'react';

const SkyBackground = () => {
  const canvasRef = useRef(null);
  const stars = useRef([]);
  const cometIndexRef = useRef(-1);
  const mouseX = useRef(window.innerWidth / 2);
  const mouseY = useRef(window.innerHeight / 2);
  let canvas, context;

  useEffect(() => {
    canvas = canvasRef.current;
    context = canvas.getContext('2d');
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    // Load background image
    const backgroundImage = new Image();
    backgroundImage.src = 'https://api.listudiosl.com/wp-content/uploads/2024/05/Home-page.jpg'; // Replace with your image path
    backgroundImage.onload = () => {
      context.drawImage(backgroundImage, 0, 0, window.innerWidth, window.innerHeight);
      addStars();
      animate();
      liuxing();
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const addStars = () => {
    const numStars = 400;
    for (let i = 0; i < numStars; i++) {
      stars.current.push({
        x: Math.random() * window.innerWidth,
        y: Math.random() * window.innerHeight,
        r: Math.random() * 3,
        ra: Math.random() * 0.05,
        alpha: Math.random(),
        vx: Math.random() * 0.2 - 0.1,
        vy: Math.random() * 0.2 - 0.1,
      });
    }
  };

  const liuxing = () => {
    const time = Math.round(Math.random() * 3000 + 33);
    setTimeout(() => {
      cometIndexRef.current = Math.ceil(Math.random() * stars.current.length);
      // liuxing();
    }, time);
  };

  const handleMouseMove = (e) => {
    mouseX.current = e.clientX;
    mouseY.current = e.clientY;
  };

  const animate = () => {
    context.clearRect(0, 0, window.innerWidth, window.innerHeight); // Clear canvas

    stars.current.forEach((star, i) => {
      if (i === cometIndexRef.current) {
        renderComet(star);
      } else {
        renderStar(star);
      }
    });

    requestAnimationFrame(animate);
  };

  const renderStar = (star) => {
    star.alpha += star.ra;

    if (star.alpha <= 0) {
      star.alpha = 0;
      star.ra = -star.ra;
      star.vx = Math.random() * 0.2 - 0.1;
      star.vy = Math.random() * 0.2 - 0.1;
    } else if (star.alpha > 1) {
      star.alpha = 1;
      star.ra = -star.ra;
    }

    star.x += star.vx;

    if (star.x >= window.innerWidth) {
      star.x = 0;
    } else if (star.x < 0) {
      star.x = window.innerWidth;
      star.vx = Math.random() * 0.2 - 0.1;
      star.vy = Math.random() * 0.2 - 0.1;
    }

    star.y += star.vy;

    if (star.y >= window.innerHeight) {
      star.y = 0;
      star.vy = Math.random() * 0.2 - 0.1;
      star.vx = Math.random() * 0.2 - 0.1;
    } else if (star.y < 0) {
      star.y = window.innerHeight;
    }

    context.beginPath();
    const bg = context.createRadialGradient(star.x, star.y, 0, star.x, star.y, star.r);
    bg.addColorStop(0, `rgba(255,255,255,${star.alpha})`);
    bg.addColorStop(1, 'rgba(255,255,255,0)');
    context.fillStyle = bg;
    context.arc(star.x, star.y, star.r, 0, Math.PI * 2, true);
    context.fill();
    context.closePath();
  };

  const renderComet = (star) => {
    star.vx = (mouseX.current - star.x) * 0.01;
    star.vy = (mouseY.current - star.y) * 0.01;

    context.beginPath();
    context.strokeStyle = `rgba(255,255,255,${star.alpha})`;
    context.lineWidth = star.r;
    context.moveTo(star.x, star.y);
    context.lineTo(star.x + star.vx, star.y + star.vy);
    context.stroke();
    context.closePath();
  };

  return <canvas ref={canvasRef} />;
};

export default SkyBackground;
