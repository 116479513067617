import React from 'react';
import Lottie from 'react-lottie';
import * as animationData from '../../src/json/lottie_file.json';

import * as animationDataBottom from '../../src/json/LI_home_bottom_line.json';

import { ReactSVG } from 'react-svg';
import Linkin from '../../src/svg/LinkedIn.svg';
import FB from '../../src/svg/FB.svg';
import Behance from '../../src/svg/Behance.svg';
const Footer = () => {

 const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const defaultOptionsBottom = {
    loop: true,
    autoplay: true, 
    animationData: animationDataBottom,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
   <div className="Footer">
     <div className="row footer-chnage">
         <div className="upper-blue-square"></div>
         <div className="lower-blue-square"></div>
         <div className="purple-square"></div>
        <div className="blue-square"></div>
      </div>
    <div className="container-fluid section-gap-footer">
      <div className="row">
        <div className="col-md-6 footer-text-col">
          <div className="text-box-deteils">
            <h2 className='roboto-light white'>Let's create <br></br><span className='roboto-black white'>something amazing </span> together</h2>
            <h3 className='roboto-light Tone-Down'><span>contact@listudiosl.com</span></h3>
            <div className="icons-lists">
              <ReactSVG src={Linkin} width='42' height='30' alt="" />
              <ReactSVG src={FB} width='42' height='30' alt="" style={{ marginLeft: '5px' }} />
              <ReactSVG src={Behance} width='42' height='30' alt="" style={{ marginLeft: '5px' }} />
            </div>
            <p className='roboto-light Tone-Down'>© 2024 LI Studios (Pvt) Ltd. All Rights Reserved.</p>
          </div>
        </div>
        <div className="col-md-6 lottie-animation-col p-0">
          <div className="lottie-footer-animation">
              <Lottie options={defaultOptions} height='auto' width={868}/>
          </div>
          
        </div>
      </div>
    </div>
    <div className="botom-animation">
      <Lottie options={defaultOptionsBottom} height='auto' width='100%'/>
    </div>
   </div>
  )
}

export default Footer