import React from 'react'

const CaseStudyInnerScreen = () => {
  return (
   
    <div className="CaseStudyInnerScreen">
        <div className="CaseStudyInnerScreen-banner">
           <div className="container-fuild">
                <div className="row CaseStudyInnerScreen-banner-row">
                   <div className="col-md-6 text-col-casestudy-innner">
                    <div className="text-box">
                        <p className="b1 roboto-light white">Casestudy</p>
                        <h2 className='roboto-light white'>Lorem ipsum dolor sit amet, consectetur adipiscing elit</h2>
                    </div>
                   </div>
                   <div className="col-md-6 image-col-casestudy-innner">
                     <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/08/CAL-1.jpg`} alt="" loading="lazy" />
                   </div>
                </div>
            </div>
        </div>
    </div>

  )
}

export default CaseStudyInnerScreen