import React from 'react'
import ReactPlayer from 'react-player';

import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
// import required modules
import { Pagination } from 'swiper/modules';

const ClientStoriesScreen = () => {
  return (
      <div className="ClientStroiesScreen">

        <div className="ClientStoiesBanner">
            <div className="container-fuild">
                <div className="row CaseStudyInnerScreen-banner-row">
                    <div className="col-md-12">
                   
                        <div className="Stroies-text">
                        <h2 className='roboto-light white'>Here's what our satisfied <span className='roboto-black white '> clients </span> are saying</h2>
                        <p className='b1 roboto-light Tone-Down'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.dolor </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="client-testimonials">
            <div className="container-fuild">
                <div className="row client-testimonial-row">
                    <div className="col-md-12 client-testimonials-col">
                         <div className="testimonail-text-box">
                         <h2 className='roboto-light white'>Client’s <span className='roboto-black white '>Testimonial</span></h2>
                         <p className='b1 roboto-light Tone-Down'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.dolor </p>
                         </div>
                    </div>
                </div>
                <div className="row testimonil-slider">
                  <div className="col-md-12 swiper-slider-col">
                  <Swiper
        slidesPerView={3}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
            <div className="slider-box">
                <div className="swiper-header">
                    <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/08/P1.png`} alt=""  width="87px" height="87px" loading='lazy' />
                    <p className='Title white roboto-medium b1'>Peter Parker</p>
                    <p className='Description b1 roboto-light Tone-Down'>IT Manager</p>
                </div>
                <p className='Description b1 roboto-light Tone-Down'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.dolor sit amet, consectetur adipiscing </p>
            </div>
        </SwiperSlide>

        <SwiperSlide>
            <div className="slider-box">
                <div className="swiper-header">
                    <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/08/P1.png`} alt=""  width="87px" height="87px" loading='lazy' />
                    <p className='Title white roboto-medium b1'>Peter Parker</p>
                    <p className='Description b1 roboto-light Tone-Down'>IT Manager</p>
                </div>
                <p className='Description b1 roboto-light Tone-Down'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.dolor sit amet, consectetur adipiscing </p>
            </div>
        </SwiperSlide>

        <SwiperSlide>
            <div className="slider-box">
                <div className="swiper-header">
                    <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/08/P1.png`} alt=""  width="87px" height="87px" loading='lazy'/>
                    <p className='Title white roboto-medium b1'>Peter Parker</p>
                    <p className='Description b1 roboto-light Tone-Down'>IT Manager</p>
                </div>
                <p className='Description b1 roboto-light Tone-Down'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.dolor sit amet, consectetur adipiscing </p>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div className="slider-box">
                <div className="swiper-header">
                    <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/08/P1.png`} alt=""  width="87px" height="87px" loading='lazy'/>
                    <p className='Title white roboto-medium b1'>Peter Parker</p>
                    <p className='Description b1 roboto-light Tone-Down'>IT Manager</p>
                </div>
                <p className='Description b1 roboto-light Tone-Down'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.dolor sit amet, consectetur adipiscing </p>
            </div>
        </SwiperSlide>
       
        
      </Swiper>
                  </div>
                </div>
            </div>
        </div>

        <div className="clients-section">
        <div className="row backgroud-chnage">
         <div className="upper-blue-square"></div>
         <div className="lower-blue-square"></div>
         <div className="purple-square"></div>
        <div className="blue-square"></div>
         </div>
        <div className="container-fluid section-gap-footer">
         
          <div className="row gap-row ">
            <div className="col-md-4 client-text-col">
              <div className="client-text">
                <h2 className='roboto-light'>Meet our <span className='roboto-black'>clients</span></h2>
                <p className='roboto-light'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
              </div>
            </div>
            <div className="col-md-8 client-log-col">
              <div className="row">
                <div className="col-2.4">
                <div className="client-img">
                 <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/05/li-client-image-1.png`} loading='lazy' alt='client-img' className='client-img' width='100%' />
                </div>
                </div>
                <div className="col-2.4">
                <div className="client-img">
                 <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/05/li-client-image-2.png`} loading="lazy" alt='client-img' className='client-img' width='100%' />
                </div>

                </div>
                <div className="col-2.4">
                <div className="client-img">
                 <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/05/li-client-image-3.png`} loading="lazy" alt='client-img' className='client-img' width='100%' />
                </div>

                </div>
                <div className="col-2.4">
                <div className="client-img">
                 <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/05/li-client-image-4.png`} loading="lazy" alt='client-img' className='client-img' width='100%' />
                </div>

                </div>
                <div className="col-2.4">
                <div className="client-img">
                 <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/05/li-client-image-5.png`} loading="lazy" alt='client-img' className='client-img' width='100%' />
                </div>

                </div>
                <div className="col-2.4">
                <div className="client-img">
                 <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/05/li-client-image-6.png`} loading="lazy" alt='client-img' className='client-img' width='100%' />
                </div>

                </div>
                <div className="col-2.4">
                <div className="client-img">
                 <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/05/li-client-image-7.png`} loading="lazy" alt='client-img' className='client-img' width='100%' />
                </div>

                </div>
                <div className="col-2.4">
                <div className="client-img">
                 <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/05/li-client-image-8.png`} loading="lazy" alt='client-img' className='client-img' width='100%' />
                </div>

                </div>
                <div className="col-2.4">
                <div className="client-img">
                 <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/05/li-client-image-9.png`} loading="lazy"  alt='client-img' className='client-img' width='100%' />
                </div>

                </div>
                <div className="col-2.4">
                <div className="client-img">
                 <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/05/li-client-image-10.png`} loading="lazy"  alt='client-img' className='client-img' width='100%' />
                </div>

                </div>
                <div className="col-2.4">
                <div className="client-img">
                 <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/05/li-client-image-11.png`} loading="lazy"  alt='client-img' className='client-img' width='100%' />
                </div>

                </div>
                <div className="col-2.4">
                <div className="client-img">
                 <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/05/li-client-image-12.png`} loading="lazy" alt='client-img' className='client-img' width='100%'/>
                </div>

                </div>
                <div className="col-2.4">
                <div className="client-img">
                 <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/05/li-client-image-13.png`} loading="lazy" alt='client-img' className='client-img' width='100%' />
                </div>

                </div>
                <div className="col-2.4">
                <div className="client-img">
                 <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/05/li-client-image-14.png`} loading="lazy" alt='client-img' className='client-img' width='100%' />
                </div>

                </div>
                <div className="col-2.4">
                <div className="client-img">
                 <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/05/li-client-image-15.png`} loading="lazy" alt='client-img' className='client-img' width='100%' />
                </div>

                </div>
                <div className="col-2.4">
                <div className="client-img">
                 <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/05/li-client-image-16.png`} loading="lazy"  alt='client-img' className='client-img' width='100%'/>
                </div>

                </div>
                <div className="col-2.4">
                <div className="client-img">
                 <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/05/li-client-image-17.png`} loading="lazy" alt='client-img' className='client-img' width='100%' />
                </div>

                </div>
                <div className="col-2.4">
                <div className="client-img">
                 <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/05/li-client-image-18.png`} loading="lazy" alt='client-img' className='client-img' width='100%' />
                </div>

                </div>
                <div className="col-2.4">
                <div className="client-img">
                 <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/05/li-client-image-19.png`} loading="lazy" alt='client-img' className='client-img' width='100%' />
                </div>

                </div>
                <div className="col-2.4">
                <div className="client-img">
                 <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/05/li-client-image.png`} loading="lazy"  alt='client-img' className='client-img' width='100%'/>
                </div>

                </div>
              </div>

            </div>
          </div>
         
        </div>
        </div>

        <div className="video-testimonials">
            <div className="container-fuild">
                <div className="row video-testimonial-row">
                    <div className="col-md-12 video-testimonials-col">
                        <div className="video-testimonails-box-">
                        <h2 className='roboto-light white'>Video <span className='roboto-black white '>Testimonials </span></h2>
                        <p className='b1 roboto-light Tone-Down'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.dolor </p>
                        </div>
                    </div>
                </div>
                <div className="row video-slider-one video-row">
                    <div className="col-md-7 video-player-col">
                        <div className="video-player-mod">
                        <ReactPlayer
                            loading="lazy"
                           url='https://www.youtube.com/watch?v=ppawFI1u6Po'
                           playing={false}
                          controls={false}
                          className='intro-banner'
                              width='100%'
                               height='440px'
          
                            />
                        </div>
                    </div>
                    <div className="col-md-5 video-text-box-col">
                       <div className="video-text-box">
                       <p className='b1 roboto-light Tone-Down'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.dolor sit amet, consectetur</p>
                       </div>
                    </div>
                </div>
                <div className="row video-slider-one video-row">
                 <div className="col-md-5 video-text-box-col">
                       <div className="video-text-box">
                       <p className='b1 roboto-light Tone-Down'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.dolor sit amet, consectetur</p>
                       </div>
                    </div>
                    <div className="col-md-7 video-player-col">
                        <div className="video-player-mod">
                        <ReactPlayer
                        loading="lazy"
                           url='https://www.youtube.com/watch?v=C7lPDGPrDPo&t=13s'
                           playing={false}
                          controls={false}
                          className='intro-banner'
                              width='100%'
                               height='440px'
          
                            />
                        </div>
                    </div>
                   
                </div>
                <div className="row video-slider-one video-row">
                    <div className="col-md-7 video-player-col">
                        <div className="video-player-mod">
                        <ReactPlayer
                        loading="lazy"
                           url='https://www.youtube.com/watch?v=Jw7s42Op2ao'
                           playing={false}
                          controls={false}
                          className='intro-banner'
                              width='100%'
                               height='440px'
          
                            />
                        </div>
                    </div>
                    <div className="col-md-5 video-text-box-col">
                       <div className="video-text-box">
                       <p className='b1 roboto-light Tone-Down'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.dolor sit amet, consectetur</p>
                       </div>
                    </div>
                </div>
                <div className="row video-slider-one video-row">
                 <div className="col-md-5 video-text-box-col">
                       <div className="video-text-box">
                       <p className='b1 roboto-light Tone-Down'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.dolor sit amet, consectetur</p>
                       </div>
                    </div>
                    <div className="col-md-7 video-player-col">
                        <div className="video-player-mod">
                        <ReactPlayer
                        loading="lazy"
                           url='https://www.youtube.com/watch?v=ppawFI1u6Po'
                           playing={false}
                          controls={false}
                          className='intro-banner'
                              width='100%'
                               height='440px'
          
                            />
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>

      </div>
  )
}

export default ClientStoriesScreen
