import React, { useState } from 'react';

const URL ='https://api.listudiosl.com'
const USER ='sunanda'
const PASSWORD ='zaq1@WSXcde3'
const TOKEN = typeof window !== 'undefined' ? btoa(`${USER}:${PASSWORD}`) : null;
const CF7_ID ='c67584a'

const ContactScreen = () => {

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    company: '',
    message: '',
  });
  const [statusMessage, setStatusMessage] = useState('');

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {

      const urlEncodedData = new URLSearchParams();
      urlEncodedData.append('your-name', formData.name);
      urlEncodedData.append('your-email', formData.email);
      urlEncodedData.append('your-phone', formData.phone);
      urlEncodedData.append('your-company', formData.company);
      urlEncodedData.append('your-message', formData.message);


      const response = await fetch(`${URL}/contact-form-7/v1/contact-forms/${CF7_ID}/feedback`, {
        method: 'POST',
        headers: {
          'Authorization': `Basic ${TOKEN}`,
          'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
        },
        body: urlEncodedData.toString(),
      });
      const result = await response.json();
      if (result.status === 'mail_sent') {
        setStatusMessage('Message sent successfully!');
      } else {
        setStatusMessage('Failed to send message. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      setStatusMessage('An error occurred. Please try again.');
    }
  };





  return (
    <div className='ContactScreen-page'>
      <div className="contact-form-section">
        <div className="container-fuild">
          <div className="row contact-row-form">
            <div className="col-md-6 contact-form-text-col">
              <div className="contact-dt">
                <div className="slider">
                 <h2 className='slider__word roboto-bold'>Have a Project? <br></br> <span className='roboto-light white'>We would love to Help</span></h2>
                 <h2 className='slider__word roboto-bold'>Have a Project? <br></br><span className='roboto-light white'>We would love to Help</span></h2>
                 <h2 className='slider__word roboto-bold'>Have a Project? <br></br><span className='roboto-light white'>We would love to Help</span></h2>
                </div>
                <p className='b1 roboto-light white'>For more work and information,<br></br>follow us on</p>
                <div className="iocns-lists-c">
                  <img src="https://api.listudiosl.com/wp-content/uploads/2024/07/LinkedIn.svg" alt="" />
                  <img src="https://api.listudiosl.com/wp-content/uploads/2024/07/FB.svg" alt="" />
                  <img src="https://api.listudiosl.com/wp-content/uploads/2024/07/Behance.svg" alt="" />
                </div>
              </div>
            </div>
            <div className="col-md-6 contact-form-col">
                <div className="contact-form-div">
                <div className="careeas-form">
                <div className="form-header">
                  <h3 className='roboto-light white'>Send a message</h3>
                </div>
                <div className="form-body">
                <form onSubmit={handleSubmit}>
                   <div className="form-roww">
                   <div className="form-group">
                       <input type="text" className="form-control"  placeholder="Full Name" name="name" value={formData.name}  onChange={handleChange}/>
                    </div>
                   </div>
                   <div className="form-roww-next">

                   <div className="form-group">
                       <input type="email" className="form-control"  placeholder="Email" name="email" value={formData.email}  onChange={handleChange} />
                    </div>
                   <div className="form-group">
                       <input type="number" className="form-control"  placeholder="Phone"  name="phone" value={formData.phone} onChange={handleChange}/>
                    </div>
                    
                   </div>
                   <div className="form-roww">
                    <div className="form-group">
                       <input type="text" className="form-control"  placeholder="Brand / Company Name"   name="company" value={formData.company} onChange={handleChange} />
                    </div>
                   </div>
                   <div className="form-roww">
                   <div className="form-group">
                       <textarea className="form-control" placeholder="Cover Message" name="message" value={formData.message} onChange={handleChange} rows="3"></textarea>
                    </div>
                   </div>

                   
 
 
                   <button type="submit" className="roboto-medium header-text-style white pushable"><span className="shadow"></span><span className="edge"></span><span className="front header-text-style">Send Message</span></button>
                   
                </form>
                {statusMessage && <p className="status-message">{statusMessage}</p>}
                </div>
              </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div className="location-section">
        <div className="container">
          <div className="row location-row m-0">
            <div className="col-md-6 location-col">
              <div className="location-info">
                <div className="img-text-ct">
                <img src="https://api.listudiosl.com/wp-content/uploads/2024/11/Sri-Lanka.png" alt="" />
                 <p className='b1 roboto-medium red'>Sri Lanka</p>
                </div>
                <div className="address-ct">
                  <h3 className='roboto-medium white text-center'>LI Studios (Pvt) Ltd</h3>
                  <p className='b1 roboto-light Light-Blue-1 text-center'>210/1E Sri Rahula Mw, Katubedda, Moratuwa, Sri Lanka. P.C 10400.
                  +94 77 3 430 151</p>
                </div>
                <div className="location-text-ct">
                  <img src="https://api.listudiosl.com/wp-content/uploads/2024/11/Location.png" alt="" />
                  <p className='b1 roboto-medium white'>Visit Us</p>
                </div>
              
                
              </div>
            </div>

            <div className="col-md-6 location-col">
            <div className="location-info">
                <div className="img-text-ct">
                <img src="https://api.listudiosl.com/wp-content/uploads/2024/11/Australia.png" alt="" />
                 <p className='b1 roboto-medium red'>Australia</p>
                </div>
                <div className="address-ct">
                  <h3 className='roboto-medium white text-center'>LI Studios Pyt</h3>
                  <p className='b1 roboto-light Light-Blue-1 text-center'>36 Hope St, Brunswick VIC 3056,
                         Australia. ABN 10400.
                     +94 77 3 430 151</p>
                </div>
                <div className="location-text-ct">
                  <img src="https://api.listudiosl.com/wp-content/uploads/2024/11/Location.png" alt="" />
                  <p className='b1 roboto-medium white'>Visit Us</p>
                </div>
              
                
              </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactScreen;
