import React,{useEffect} from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import './App.css';

import Header from './Includes/Header.jsx';
import Footer from './Includes/Footer.jsx';
import SkyBackground from './Components/skyBacgroud.jsx';
import AnimationsSky from './Components/animationsky.jsx';


const App = () => {
  const location = useLocation();
  
  const showSkyComponents = !['/about', '/casestudyinner'].includes(location.pathname);


  const pageId = location.pathname === '/' ? 'home' : location.pathname.slice(1);

  useEffect(() => {
    const rootElement = document.getElementById('root');
    if (rootElement) {
      rootElement.className = pageId; 
      rootElement.setAttribute('data-page-id', pageId); 
    }
  }, [pageId]); 

  return (
    <>
      <Header />
      <div className='Li-web-pages'>
        <div className='div-sky-bg'>
          {showSkyComponents && <AnimationsSky />}
          {showSkyComponents && <SkyBackground />}
        </div>
        <Outlet />
      </div>
      <Footer />
    </>
  );
};

export default App;
