import React from "react";

const AnimationsSky =()=>{
    return(
        <div className="absolute w-full overflow-hidden">
      <div id="stars" className="w-full" style={{ height: '700px' }}></div>

      <span className="pointer-events-none absolute left-1/2 top-1/2 h-0.5 w-0.5 rotate-[135deg] animate-meteor rounded-[9999px] bg-slate-500 shadow-[0_0_0_1px_#ffffff10]"
        style={{ top: '500px', left: '250px', animationDelay: '1.842674s', animationDuration: '5s' }}>
        <div className="pointer-events-none absolute top-1/2 -z-10 h-[1px] w-[50px] -translate-y-1/2 bg-gradient-to-r from-slate-500 to-transparent"></div>
      </span>

      <span className="pointer-events-none absolute left-1/2 top-1/2 h-0.5 w-0.5 rotate-[135deg] animate-meteor rounded-[9999px] bg-slate-500 shadow-[0_0_0_1px_#ffffff10]"
        style={{ top: '470px', left: '450px', animationDelay: '1.842674s', animationDuration: '3s' }}>
        <div className="pointer-events-none absolute top-1/2 -z-10 h-[1px] w-[50px] -translate-y-1/2 bg-gradient-to-r from-slate-500 to-transparent"></div>
      </span>

      <span className="pointer-events-none absolute left-1/2 top-1/2 h-0.5 w-0.5 rotate-[135deg] animate-meteor rounded-[9999px] bg-slate-500 shadow-[0_0_0_1px_#ffffff10]"
        style={{ top: '470px', left: '1350px', animationDelay: '1.842674s', animationDuration: '4s' }}>
        <div className="pointer-events-none absolute top-1/2 -z-10 h-[1px] w-[50px] -translate-y-1/2 bg-gradient-to-r from-slate-500 to-transparent"></div>
      </span>

      <span className="pointer-events-none absolute left-1/2 top-1/2 h-0.5 w-0.5 rotate-[135deg] animate-meteor rounded-[9999px] bg-slate-500 shadow-[0_0_0_1px_#ffffff10]"
        style={{ top: '400px', left: '1550px', animationDelay: '3.842674s', animationDuration: '4s' }}>
        <div className="pointer-events-none absolute top-1/2 -z-10 h-[1px] w-[50px] -translate-y-1/2 bg-gradient-to-r from-slate-500 to-transparent"></div>
      </span>
    </div>
    )
}

export default AnimationsSky;