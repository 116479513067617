import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { FileUploader } from "react-drag-drop-files";

const fileTypes = ["JPG", "PNG", "GIF"];

const CareersScreen = () => {

  const [file, setFile] = useState(null);
  const handleChange = (file) => {
    setFile(file);
  };

  return (
    <div className="CareersScreen">
      <div className="CareersBody">
        <div className="container-fluid">
          <div className="row Carees-row">
            <div className="col-md-6 col-carrees-one">
               <div className="carrres-text-box">
                <div className="arrow-cions">
                  <Link reloadDocument to= "/lifeli"><img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/08/arrow.svg`} alt="" width="24px" height="22px" /></Link>
                </div>
                <div className="brench">
                  <p className='b1 roboto-light Tone-Down'>Life at LI / Careers at LI / Software Engineer</p>
                </div>
                <div className="jobs-body">
                  <div className="job-header">
                    <h2 className='roboto-light white'>Software <span className='roboto-black white '>Engineer</span> </h2>
                  </div>
                  <div className="jobs-description">
                    <h3 className='roboto-bold white'>About the Job</h3>
                    <p className='roboto-light white b1'>You will be essential in creating the upcoming generation of applications as a software engineer. As part of an Agile team, you will be designing, building, troubleshooting, maintaining, optimizing, and scaling cloud-based services and web applications in order to drive innovation into our product suite. Working with contemporary web, server, and database technologies such as React, Node.js, MongoDB, Entity Framework, and SQL Server, you will be employed as a full-stack developer.</p>
                    <div className="key-requiremts">
                      <p className='b1 roboto-medium white'>Roles and Responsibility</p>
                      <ul className="key-ul">
                        <li className="key-li roboto-light Tone-Down b1">Work with a team to deliver features and improve architecture.</li>
                        <li className="key-li roboto-light Tone-Down b1">Own full software development lifecycle: design, development, testing, and operating in production.</li>
                        <li className="key-li roboto-light Tone-Down b1">Build solutions with a high level of reliability, scalability, and security.</li>
                        <li className="key-li roboto-light Tone-Down b1">Demonstrate an ability to learn and adopt relevant new frontend and backend technologies, tools, methods, and processes to leverage in your solutions.</li>
                        <li className="key-li roboto-light Tone-Down b1">You are passionate about customers and product quality.</li>
                      </ul>
                    </div>
                    <div className="key-requiremts">
                      <p className='b1 roboto-medium white'>Requirements</p>
                      <ul className="key-ul">
                        <li className="key-li roboto-light Tone-Down b1">Work with a team to deliver features and improve architecture.</li>
                        <li className="key-li roboto-light Tone-Down b1">Own full software development lifecycle: design, development, testing, and operating in production.</li>
                        <li className="key-li roboto-light Tone-Down b1">Build solutions with a high level of reliability, scalability, and security.</li>
                        <li className="key-li roboto-light Tone-Down b1">Demonstrate an ability to learn and adopt relevant new frontend and backend technologies, tools, methods, and processes to leverage in your solutions.</li>
                        <li className="key-li roboto-light Tone-Down b1">You are passionate about customers and product quality.</li>
                        <li className="key-li roboto-light Tone-Down b1">You are passionate about customers and product quality.</li>
                        <li className="key-li roboto-light Tone-Down b1">You are passionate about customers and product quality.</li>
                        <li className="key-li roboto-light Tone-Down b1">You are passionate about customers and product quality.</li>
                        <li className="key-li roboto-light Tone-Down b1">You are passionate about customers and product quality.</li>
                      </ul>
                    </div>
                  </div>
                </div>
               </div>
            </div>
            <div className="col-md-6 col-carrees-two">
              <div className="careeas-form">
                <div className="form-header">
                  <h3 className='roboto-light white'>Apply for the Job</h3>
                </div>
                <div className="form-body">
                <form>
                   <div className="form-roww">
                   <div className="form-group">
                       <input type="text" className="form-control"  placeholder="Full Name"/>
                    </div>
                   </div>
                   <div className="form-roww-next">
                   <div className="form-group">
                       <input type="number" className="form-control"  placeholder="Phone"/>
                    </div>
                    <div className="form-group">
                       <input type="email" className="form-control"  placeholder="Email"/>
                    </div>
                   </div>
                   <div className="form-roww">
                   <div className="form-group">
                       <textarea class="form-control" placeholder="Cover Message"  rows="3"></textarea>
                    </div>
                   </div>

                   <div className="form-roww">
                   <div className="form-group fileuploader">
                        <FileUploader handleChange={handleChange} multiple={false} hoverTitle="Select Resume" maxSize={2} name="file" types={fileTypes} label="Select Resume" />
                    </div>
                   </div>
 
 
                   <button className="roboto-medium header-text-style white pushable"><span className="shadow"></span><span className="edge"></span><span className="front header-text-style">Submit</span></button>
                </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default CareersScreen
